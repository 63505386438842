<template>
  <div class="content">
    <!--  基础信息 -->
    <span  class="remarksAlink">食品药品监管总局关于进口医疗器械注册申请人和备案人名称使用中文的公告：<a target="_blank" href="http://www.gov.cn/xinwen/2017-11/02/content_5236633.htm">http://www.gov.cn/xinwen/2017-11/02/content_5236633.htm</a></span>
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel :showIcon="true" label="公司名称(外文)"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="true" v-model="form.factory_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine">
        <FromLabel  label="公司名称(中文)"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="true" v-model="form.outside_factory_cname" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="saveDiv clearfix">
      <span class="pageBtn finger btnReset fr marginLeft20" @click="back">返回</span>
    </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'businessLicenseInfo',
  components: {
    FromLabel,
  },
  data() {
    return {
      // 控制图片显示
      loading: false,
      isBand: false,
      license: [],

      // 厂家列表
      FactoryList: [],
      factName: '',
      // 控制是否是供应商
      issuply: false,
      form: {
        factory_name: '', // 厂家名称
        factory_id: this.$route.query.id,
        factory_approval: '',
        outside_factory_cname: '',
        // subfactory_id:this.
      },
      contract: '',
      LicenseName: '',
      // 工厂id
      factory_id: this.$route.query.id,
    }
  },
  //
  created() {},
  mounted() {
    // 调用接口控制回显示
    this.getoulandFactoryInfo()
    this.judeIsband()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
      }
    },
    // 获取数据
    getoulandFactoryInfo() {
      let data = {
        subfactory_id: this.$route.query.subfactory_id,
      }
      this.$http.get(this.$api.abroadFactory, data, true).then(res => {
        console.log(res)
        this.$root.eventHub.$emit('sendfactoryApproval', res.data[0].factory_approval)
        this.form = res.data[0]
      })
    },
    // 下一步
    // nextstep() {
    //   if (this.form.factory_name == '') {
    //     this.$Message.warning('请填写公司名称')
    //     return
    //   }
    //   let data = this.form
    //   if (this.$route.query.id == '' && this.$route.query.id == null && this.$route.query.id == undefined) {
    //     delete data.factory_id
    //   }
    //   this.$http.post(this.$api.factoryManageFactoryInformation, data, false).then(res => {
    //     console.log(res)
    //     this.$Message.success('保存成功')
    //     this.$emit('sendClickIndex', 2)
    //   })
    // },
  },
  watch: {},
}
</script>

<style scoped lang="less">
.saveDiv {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 20px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.imgstyle {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.iviewIptWidth1075px {
  width: 1075px;
}

.content {
  height: 100%;
  .fromDiv {
    position: relative;
    height: 100%;
    .saveDiv {
      position: absolute;
      right: 23px;
      bottom: 5px;
    }
  }
}

// .button {
//   width: 50px;
//   height: 50px;
// }
.fontSize14px {
  font-size: 14px;
}
.iviewIptWidth170 {
  width: 170px;
}
.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.iviewidth700px {
  width: 700px;
}

.moreHeight {
  height: 100px;
}
</style>
