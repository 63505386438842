<template>
  <div>
    <div class="formLabel fl">
      <span class="icon" v-if="showIcon">*</span>
      <span :class="{ redColor: fontColor }">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'formLabel',
  props: {
    label: String, // 标题
    showIcon: Boolean || false, // 是否显示图标
    fontColor: Boolean || false, // 是否最近修改
  },
}
</script>

<style scoped lang="less">
.redColor {
  color: #f73333 !important;
}
</style>
