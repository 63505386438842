<template>
  <div class="page">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
      </li>
    </ul>
     
    <div class="mainBox">
     
      <div class="tabelDiv">
        <oulandFactoryInfo @sendClickIndex="getClickIndex($event)" v-show="clickIndex == 1"></oulandFactoryInfo>
        <oulandApproval v-show="clickIndex == 2"></oulandApproval>
      </div>
    </div>
  </div>
</template>

<script>
import oulandFactoryInfo from './outlandManage/oulandFactoryInfo'
import oulandApproval from './outlandManage/oulandApproval'
export default {
  name: 'manufacturerManage',
  components: {
    oulandFactoryInfo,
    oulandApproval,
  },
  computed: {},
  mounted() {},
  data() {
    return {
      isLoad: false,
      navFrist: [
        {
          name: '厂家信息',
          type: 1,
        },
        {
          name: '首营审批',
          type: 2,
        },
      ],
      clickIndex: 1,
      // 用来存储目前进行到的步骤
      currentIndex: 1,
      // 存储医院id
      ispromised1: false,
      // 控制跳转 和是否可选
      datmain: '',
    }
  },
  methods: {
    changePage(e) {},
    getdatmain(data) {
      this.datmain = data
      console.log(this.datmain)
    },
    clickNav(type) {
      this.clickIndex = type
    },
    getClickIndex(e) {
      this.clickIndex = e
    },
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .mainBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 16x 20px rgb(0, 0, 0);
    overflow-y: scroll;
  }
  .tabelDiv {
    flex: 1;
    border-radius: 0 8px 8px 8px;
    padding: 20px 0px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
.saveDiv {
  padding: 0 23px 29px 0;
}
</style>
