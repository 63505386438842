<template>
  <div class="content">
    <div class="box">
      <!-- 采购部审核意见 -->
      <div class="tableTitle" style="margin-top: -10px;"><span class="xh">*</span><span class="title"> 采购部审核意见 </span></div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg1" v-model="form.purchasing_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg1" v-model="form.purchasing_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup @on-change="change1" v-model="form.purchasingn_state">
              <Radio :disabled="judg1" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg1" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg1" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.purchasing_date" @on-open-change="openChange($event, 'purchasing_date')" @on-change="changeTime($event, 'purchasing_date')"></DatePicker>
          </div>
        </div>
      </div>

      <!-- 质量管理部门审核意见 -->
      <div class="tableTitle"><span class="xh">*</span><span class="title"> 质量管理部门审核意见 </span></div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg2" v-model="form.quality_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg2" v-model="form.qualityn_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.quality_state">
              <Radio :disabled="judg2" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg2" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg2" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.quality_date" @on-open-change="openChange($event, 'quality_date')" @on-change="changeTime($event, 'quality_date')"></DatePicker>
          </div>
        </div>
      </div>

      <!-- 财务部审核意见 -->
      <div class="tableTitle"><span class="xh">*</span><span class="title"> 财务部审核意见 </span></div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg3" v-model="form.finance_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg3" v-model="form.finance_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.finance_state">
              <Radio :disabled="judg3" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg3" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg3" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.finance_date" @on-open-change="openChange($event, 'finance_date')" @on-change="changeTime($event, 'finance_date')"></DatePicker>
          </div>
        </div>
      </div>

      <!-- 总经理或管理者代表意见 -->
      <div class="tableTitle"><span class="xh">*</span><span class="title"> 总经理意见 </span></div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg4" v-model="form.admin_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg4" v-model="form.admin_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.admin_state">
              <Radio :disabled="judg4" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg4" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg4" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.admin_date" @on-open-change="openChange($event, 'admin_date')" @on-change="changeTime($event, 'admin_date')"></DatePicker>
          </div>
        </div>
      </div>
    </div>
    <div class="saveDiv clearfix">
      <span class="pageBtn finger btnReset fr marginLeft20" @click="back">返回</span>
      <div class="pageBtn finger btnSure fr marginLeft20" v-show="this.RoleId == '1' && isBand" type="primary" @click="gothrough()">一键通过</div>
      <div class="pageBtn finger btnSure fr marginLeft20" v-show="isBand" type="primary" @click="isput = true">保存</div>
    </div>
    <!-- 启用模态框 -->
    <Modal v-model="isput" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认保存？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="save()">确定</span>
          <span class="pageBtn finger btnCancle" @click="isput = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'businessApproval',
  components: {
    FromLabel,
  },
  data() {
    return {
      isput: false,
      type: '',
      license: [],
      isLoad: false,
      isBand: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      chooseGroup: '驳回',
      form: {
        factory_id: this.$route.query.id, // 工厂ID
        factory_approval: null,
        purchasing_opinion: null, // 财务部审核意见
        purchasing_person: null, // 财务部负责人签名
        purchasingn_state: null, //	财务部状态0=驳回 1=同意
        purchasing_date: null, // 财务部审核日期
        quality_opinion: null, //	质量部审核意见
        qualityn_person: null, //	质量部负责人签名
        quality_state: null, // 质量部盛和状态0=驳回 1=同意
        quality_date: null, //	质量部审核日期
        finance_opinion: null, // 财务部审核意见
        finance_person: null, //	财务部负责人签字
        finance_state: null, // 财务部审核状态0=驳回 1=同意
        finance_date: null, // 财务部审核日期
        admin_opinion: null, //	管理者审核意见
        admin_person: null, // 管理部负责人签字
        admin_state: null, // 管理部审核状态0=驳回 1=同意
        admin_date: null, //	管理部审核日期
      },
      data_maintenanc: '',
      // 从本地获取登陆的角色id
      RoleId: '',
      judg1: true,
      judg2: true,
      judg3: true,
      judg4: true,
      flag: true,
    }
  },
  created() {},
  mounted() {
    // 获取juseid
    this.RoleId = window.localStorage.getItem('userRole')
    this.$root.eventHub.$off('sendfactoryApproval')
    this.$root.eventHub.$on('sendfactoryApproval', factory_approval => {
      console.log(factory_approval, '首印审批')
      this.factory_approval = factory_approval
      this.getApproval()
    })
    this.judeIsband()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    openChange(e, name) {
      if (!this.form[name]) {
        this.form[name] = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      }
    },
    // 时间改变
    changeTime(e, name) {
      console.log(e, 'e')
      this.form[name] = e
    },
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = false
        // 全部不让看
        this.judg1 = true
        this.judg2 = true
        this.judg3 = true
        this.judg4 = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = true
        console.log(this.RoleId, '11111111111111111')
        if (this.RoleId == '1') {
          this.judg1 = false
          this.judg2 = false
          this.judg3 = false
          this.judg4 = false
        }

        // 如果为总经理  角色为2 全都可以编辑
        if (this.RoleId == '2') {
          this.judg1 = false
          this.judg2 = false
          this.judg3 = false
          this.judg4 = false
        }

        // 如果为采购部
        if (this.RoleId == '3' || this.RoleId == '4') {
          this.judg1 = false
          this.judg2 = true
          this.judg3 = true
          this.judg4 = true
        }

        // 如果为质量管理部
        if (this.RoleId == '8' || this.RoleId == '15') {
          this.judg1 = true
          this.judg2 = false
          this.judg3 = true
          this.judg4 = true
        }

        // 如果为财务部
        if (this.RoleId == '10' || this.RoleId == '11') {
          this.judg1 = true
          this.judg2 = true
          this.judg3 = false
          this.judg4 = true
        }

        // 如果是别的角色
        if (this.RoleId != '1' && this.RoleId != '2' && this.RoleId != '3' && this.RoleId != '4' && this.RoleId != '8' && this.RoleId != '10' && this.RoleId != '11' && this.RoleId != '15') {
          this.judg1 = true
          this.judg2 = true
          this.judg3 = true
          this.judg4 = true
        }
      }
    },

    change1(e) {
      console.log(e)
    },
    changePage(e) {},
    // 一件同意
    gothrough() {
      if (!this.flag) return
      this.flag = false
      this.$http.get(this.$api.factoryOnePass, false).then(res => {
        console.log(res)
        this.form = res.data
        this.form.purchasing_date = this.$moment.unix(res.data.purchasing_date).format('YYYY-MM-DD HH:mm:ss')
        this.form.quality_date = this.$moment.unix(res.data.quality_date).format('YYYY-MM-DD HH:mm:ss')
        this.form.finance_date = this.$moment.unix(res.data.finance_date).format('YYYY-MM-DD HH:mm:ss')
        this.form.admin_date = this.$moment.unix(res.data.admin_date).format('YYYY-MM-DD HH:mm:ss')
        this.form.purchasingn_state = res.data.purchasingn_state + ''
        this.form.admin_state = res.data.admin_state + ''
        this.form.finance_state = res.data.finance_state + ''
        this.form.quality_state = res.data.quality_state + ''
        this.form.factory_id = this.$route.query.subfactory_id
        this.$http.post(this.$api.factoryManageFirstmarketingapproval, this.form, true).then(res => {
          console.log(res)
          this.flag = true
          this.$router.push('/supplierManage')
          this.$Message.success('保存成功')
        })
      })
      sessionStorage.setItem('updataCache', '0')
    },
    // 保存事件
    save() {
      if (!this.flag) return
      this.flag = false
      let data = {
        factory_id: this.$route.query.subfactory_id, // 工厂ID
        factory_approval: this.factory_approval,
        purchasing_opinion: this.form.purchasing_opinion, // 财务部审核意见
        purchasing_person: this.form.purchasing_person, // 财务部负责人签名
        purchasingn_state: this.form.purchasingn_state, //	财务部状态0=驳回 1=同意
        purchasing_date: this.form.purchasing_date, // 财务部审核日期
        quality_opinion: this.form.quality_opinion, //	质量部审核意见
        qualityn_person: this.form.qualityn_person, //	质量部负责人签名
        quality_state: this.form.quality_state, // 质量部盛和状态0=驳回 1=同意
        quality_date: this.form.quality_date, //	质量部审核日期
        finance_opinion: this.form.finance_opinion, // 财务部审核意见
        finance_person: this.form.finance_person, //	财务部负责人签字
        finance_state: this.form.finance_state, // 财务部审核状态0=驳回 1=同意
        finance_date: this.form.finance_date, // 财务部审核日期
        admin_opinion: this.form.admin_opinion, //	管理者审核意见
        admin_person: this.form.admin_person, // 管理部负责人签字
        admin_state: this.form.admin_state, // 管理部审核状态0=驳回 1=同意
        admin_date: this.form.admin_date, //	管理部审核日期
      }
      // 如果是管理员  或者总经理  就要填写所有的
      if (this.RoleId == '1' || this.RoleId == '2') {
        if (!data.purchasing_opinion) {
          this.$Message.warning('请填写采购部意见')
          return
        }
        if (!data.purchasing_person) {
          this.$Message.warning('请填写采购部负责人签字')
          return
        }
        if (!data.purchasingn_state) {
          this.$Message.warning('请选择采购部同意或驳回选项')
          return
        }
        if (!data.purchasing_date) {
          this.$Message.warning('请填写采购部签字日期')
          return
        }
        if (!data.quality_opinion) {
          this.$Message.warning('请填写质量管理部意见')
          return
        }
        if (!data.qualityn_person) {
          this.$Message.warning('请填写质量管理部门负责人签字')
          return
        }
        if (!data.quality_state) {
          this.$Message.warning('请选择质量管理部同意或驳回选项')
          return
        }
        if (!data.quality_date) {
          this.$Message.warning('请填写质量管理部门签字日期')
          return
        }
        if (!data.finance_opinion) {
          this.$Message.warning('请填写财务部意见')
          return
        }
        if (!data.finance_person) {
          this.$Message.warning('请填写财务部负责人签字')
          return
        }
        if (!data.finance_state) {
          this.$Message.warning('请选择财务部同意或驳回选项')
          return
        }
        if (!data.finance_date) {
          this.$Message.warning('请填写财务部签字日期')
          return
        }
        if (!data.admin_opinion) {
          this.$Message.warning('请填写总经理意见')
          return
        }
        if (!data.admin_person) {
          this.$Message.warning('请填写总经理责人签字')
          return
        }
        if (!data.admin_state) {
          this.$Message.warning('请选择总经理同意或驳回选项')
          return
        }
        if (!data.admin_date) {
          this.$Message.warning('请填写总经理签字日期')
          return
        }
      }

      // 如果是采购部
      if (this.RoleId == '3' || this.RoleId == '4') {
        if (!data.purchasing_person) {
          this.$Message.warning('请填写采购部审核意见')
          return
        }
        if (!data.purchasing_person) {
          this.$Message.warning('请填写采购部负责人签字')
          return
        }
        if (!data.purchasingn_state) {
          this.$Message.warning('请选择采购部同意或驳回选项')
          return
        }
        if (!data.purchasing_date) {
          this.$Message.warning('请填写采购部签字日期')
          return
        }
      }

      // 如果是质量管理部门
      if (this.RoleId == '8') {
        if (!data.quality_opinion) {
          this.$Message.warning('请填写质量管理部意见')
          return
        }
        if (!data.qualityn_person) {
          this.$Message.warning('请填写质量管理部门负责人签字')
          return
        }
        if (!data.quality_opinion) {
          this.$Message.warning('请填写质量管理部意见')
          return
        }
        if (!data.quality_date) {
          this.$Message.warning('请填写质量管理部门签字日期')
          return
        }
      }

      // 如果是财务部
      if (this.RoleId == '10' || this.RoleId == '11') {
        if (!data.finance_opinion) {
          this.$Message.warning('请填写财务部意见')
          return
        }
        if (!data.finance_person) {
          this.$Message.warning('请填写财务部负责人签字')
          return
        }
        if (!data.finance_state) {
          this.$Message.warning('请选择财务部同意或驳回选项')
          return
        }
        if (!data.finance_date) {
          this.$Message.warning('请填写财务部签字日期')
          return
        }
      }
      this.$http.post(this.$api.factoryManageFirstmarketingapproval, data, true).then(res => {
        this.flag = true
        this.$router.push('/supplierManage')
        this.$Message.success('保存成功')
      })
      sessionStorage.setItem('updataCache', '0')
    },
    // 同意id获取工厂首映审核
    getApproval() {
      if (this.factory_approval != null) {
        let data = {
          factory_id: this.$route.query.subfactory_id,
          factory_approval: this.factory_approval,
        }
        this.$http.get(this.$api.factoryManageFirstmarketingapproval, data, true).then(res => {
          console.log(res)
          this.form = res.data
          if (this.form.purchasing_date == '') {
            this.form.purchasing_date = null
          }
          if (this.form.quality_date == '') {
            this.form.quality_date = null
          }
          if (this.form.finance_date == '') {
            this.form.finance_date = null
          }
          if (this.form.admin_date == '') {
            this.form.admin_date = null
          }
          this.form.purchasing_date = this.$moment.unix(this.form.purchasing_date).format('YYYY-MM-DD HH:mm:ss')
          this.form.quality_date = this.$moment.unix(this.form.quality_date).format('YYYY-MM-DD HH:mm:ss')
          this.form.finance_date = this.$moment.unix(this.form.finance_date).format('YYYY-MM-DD HH:mm:ss')
          this.form.admin_date = this.$moment.unix(this.form.admin_date).format('YYYY-MM-DD HH:mm:ss')

          if (this.form.purchasing_date == '1970-01-01 08:00:00') {
            this.form.purchasing_date = null
          }
          if (this.form.quality_date == '1970-01-01 08:00:00') {
            this.form.quality_date = null
          }
          if (this.form.finance_date == '1970-01-01 08:00:00') {
            this.form.finance_date = null
          }
          if (this.form.admin_date == '1970-01-01 08:00:00') {
            this.form.admin_date = null
          }
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.fontSize14px {
  font-size: 14px;
}
.saveDiv {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 23px;
}
.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 10px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}
.marginRight20 {
  margin-right: 20px;
}
/deep/ .fullInput {
  width: 100%;
  height: 85px;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #e8eaec;
  .ivu-input-wrapper {
    height: 100% !important;
  }
  textarea.ivu-input {
    height: 100% !important;
  }
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.xh {
  color: red;
  margin-top: -10px;
  font-size: 16px;
}
</style>
